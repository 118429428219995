.clients {
	.section-inner {
		// padding-top: $clients--padding-t__mobile;
		// padding-bottom: $clients--padding-b__mobile;
	}

	.section-header {
		padding-bottom: $clients-header--padding__mobile;
	}
}

.clients-inner {
	ul {
		margin: 0;
		display: flex;
	}

	li {
		padding: $clients-items--padding-v $clients-items--padding-h;
	}
}

@include media( '>medium' ) {
	.clients {
		.section-inner {
			padding-top: $clients--padding-t__desktop;
			padding-bottom: $clients--padding-b__desktop;
		}

		.section-header {
			padding-bottom: $clients-header--padding__desktop;
		}

		.clients-inner {
			ul {
				flex-direction: row;
			}
		}
	}
}
