.roadmap {

	.section-inner {
		padding-top: $roadmap--padding-t__mobile;
		padding-bottom: $roadmap--padding-b__mobile;
	}

	.section-header {
		// padding-bottom: $roadmap-header--padding__mobile;
		padding-bottom: 0;
	}
}

@include media( '>medium' ) {

	.roadmap {

		.section-inner {
			padding-top: $roadmap--padding-t__desktop;
			padding-bottom: $roadmap--padding-b__desktop;
		}

		.section-header {
			// padding-bottom: $roadmap-header--padding__desktop; 
			padding-bottom: 0;
		}
	}
}
