.pricing {
	.tiles-item-inner {
		background: get-color(light, 1);
	}
}

.pricing-item-header {
	@include divider(after);

	&::after {
		max-width: 64px;
	}
}

.pricing-item-features-title {
	@include font-family(heading);
}

ul.pricing-item-features-list {
	@include divider(after);

	li {
		margin-bottom: 0;
		padding: 14px 0;
		@include divider(before);

		&::after {
			content: '';
			display: block;
			width: 24px;
			height: 24px;
			margin-right: 12px;
			background-image: inline-svg('<svg width="24" height="24" xmlns="http://www.w3.org/2000/svg"><g fill="none" fill-rule="evenodd"><circle fill="' + get-color(light, 3) + '" cx="12" cy="12" r="12"/><path fill="' + get-color(dark, 3) + '" fill-rule="nonzero" d="M7 11h10v2H7z"/></g></svg>');
			background-repeat: no-repeat;
			order: -1;
		}

		&.is-checked {

			&::after {
				background-image: inline-svg('<svg width="24" height="24" xmlns="http://www.w3.org/2000/svg"><g fill="none" fill-rule="evenodd"><circle fill="' + get-color(secondary, 4) + '" cx="12" cy="12" r="12"/><path fill="' + get-color(secondary, 1) + '" fill-rule="nonzero" d="M10.5 12.267l-2.5-1.6-1 1.066L10.5 16 17 9.067 16 8z"/></g></svg>');
			}
		}
	}
}