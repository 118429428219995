.testimonial {
	&.has-bg-color {
		position: relative;
		background-color: transparent;

		&::before {
			content: '';
			position: absolute;
			top: 0;
			left: 0%;
			width: 100%;
			height: calc(100% - 20px);
			background: get-color(dark, 1);
			z-index: -3;
		}
	}	

	.tiles-item-inner {
		padding-left: 34px;
		background: get-color(light, 1);
	}

	.testimonial-item-name {
		margin-left: 34px;
		color: get-color(secondary, 2);
		
		a {
			color: get-color(secondary, 2) !important;
			text-decoration: none !important;
		}
	}	

	&.invert-color,
	.invert-color {
		.tiles-item-inner {
			background: get-color(dark, 2);
		}
		
		.testimonial-item-name {
			// color: get-color(alert, success);
			
			a {
				color: get-color(alert, success) !important;
			}
		}			
	}
}