.testimonial {
	position: relative;

	.paint1 {
		top: 12%;
		left: 51%;
		width: 143px;
		height: 72px;
		position: absolute;
		background-size: 100% 100%;
		transform: translateX(-50%);
		background-image: url('../../../images/paint1.png');
	}

	.paint2 {
		top: 50%;
		left: 400px;
		width: 143px;
		height: 72px;
		position: absolute;
		background-size: 100% 100%;
		transform: translateX(-50%);
		background-image: url('../../../images/paint2.png');
	}

	.paint3 {
		top: 44%;
		right: 250px;
		width: 143px;
		height: 72px;
		position: absolute;
		background-size: 100% 100%;
		transform: translateX(-50%);
		background-image: url('../../../images/paint3.png');
	}

	.paint4 {
		bottom: 12%;
		left: 49%;
		width: 143px;
		height: 72px;
		position: absolute;
		background-size: 100% 100%;
		transform: translateX(-50%);
		background-image: url('../../../images/paint4.png');
	}

	.paint5 {
		top: 46%;
		left: 36%;
		z-index: 1;
		width: 50px;
		height: 50px;
		border-radius: 50%;
		position: absolute;
		background: #FFC000;
	}

	.paint6 {
		top: 64%;
		left: 60%;
		width: 50px;
		height: 50px;
		border-radius: 50%;
		position: absolute;
		background: #19C3D0;
	}

	.game-items {
		display: flex;
		height: 390px;
		position: relative;
		justify-content: center;
	}

	.section-inner {
		padding-top: $testimonial--padding-t__mobile;
		// padding-bottom: $testimonial--padding-b__mobile;
	}

	.section-header {
		padding-bottom: $testimonial-header--padding__mobile;
	}

	.tiles-wrap {
		@if ( $testimonial-items--padding__mobile != null ) {
			margin-right: -($testimonial-items--padding__mobile / 2);
			margin-left: -($testimonial-items--padding__mobile / 2);
			margin-top: -($testimonial-items--padding__mobile / 2);

			&:last-of-type {
				margin-bottom: -($testimonial-items--padding__mobile / 2);
			}

			&:not(:last-of-type) {
				margin-bottom: ($testimonial-items--padding__mobile / 2);
			}
		}

		&.push-left {
			&::after {
				flex-basis: $testimonial-item--width;
				max-width: $testimonial-item--width;
				@if ( $testimonial-items--padding__mobile != null ) {
					padding-left: $testimonial-items--padding__mobile / 2;
					padding-right: $testimonial-items--padding__mobile / 2;
				}
			}
		}
	}

	.tiles-item {
		flex-basis: $testimonial-item--width;
		max-width: $testimonial-item--width;
		@if ( $testimonial-items--padding__mobile != null ) {
			padding: $testimonial-items--padding__mobile / 2;
		}
	}

	.game-item {
		padding: 0;
		margin-right: -100px !important;
		box-sizing: border-box;

		.tiles-item-inner {
			width: 262px;
			height: 322px;
			display: flex;
			position: relative;
			flex-direction: column;
			justify-content: space-between;

			&:hover {
				.goto-btn {
					display: none;
				}

				.testimonial-item-name {
					display: none;
				}

				&::before {
					content: "";
					top: 0;
					left: 23px;
					width: 212px;
					height: 100%;
					opacity: 0.4;
					border-radius: 12px 19px 13px 19px;
					position: absolute;
					background: #000000;
					transform: skewX(-8.8deg);
				}
			}

			.testimonial-item-name {
				width: 150px;
				display: inline-block;
			}

			&.isHide {
				display: none;
			}
		}

		.goto-btn {
			width: 160px;
			display: flex;
			line-height: 1;
			color: #ffffff;
			align-items: center;
			border-radius: 90px;
			justify-content: center;
			background: rgba(255, 105, 0, .8);
		}
	}

	.container {
		width: 1200px;
	}

	.tiles-item-inner {
		padding-top: $testimonial-item--inner-padding-v;
		padding-bottom: $testimonial-item--inner-padding-v;
		padding-left: $testimonial-item--inner-padding-h;
		padding-right: $testimonial-item--inner-padding-h;
	}
}

.testimonial-item-content {
	z-index: 1;
	width: 170px;
	flex-grow: 1;
	margin-top: 0;
	font-size: 14px;
	white-space: pre-wrap;
	box-sizing: border-box;

	p {
		color: rgba(255, 255, 255, .8);
	}
}

.testimonial-item-source {
	display: inline-flex; // to allow centering when using .content-center on a parent tag
	flex-wrap: wrap;
	align-items: center;
}

@include media( '>medium' ) {
	.testimonial {
		.section-inner {
			padding-top: $testimonial--padding-t__desktop;
			padding-bottom: $testimonial--padding-b__desktop;
		}

		.section-header {
			padding-bottom: $testimonial-header--padding__desktop;
		}

		@if ( $testimonial-items--padding__desktop != null ) {
			.tiles-wrap {
				margin-right: -($testimonial-items--padding__desktop / 2);
				margin-left: -($testimonial-items--padding__desktop / 2);
				margin-top: -($testimonial-items--padding__desktop / 2);

				&:last-of-type {
					margin-bottom: -($testimonial-items--padding__desktop / 2);
				}

				&:not(:last-of-type) {
					margin-bottom: ($testimonial-items--padding__desktop / 2);
				}

				&.push-left {

					&::after {
						padding-left: $testimonial-items--padding__desktop / 2;
						padding-right: $testimonial-items--padding__desktop / 2;
					}
				}
			}

			.tiles-item {
				padding: $testimonial-items--padding__desktop / 2;
			}
		}
	}
}

.testimonial-inner {
	padding-bottom: 50px;

	.section-header {
		z-index: 0;
		position: relative;
	}
}