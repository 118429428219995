.site-footer-inner {
	padding: $footer--padding__mobile 0;
	
}

.footer-top {
	padding-bottom: $footer-inner--padding-v / 2;
}

.footer-bottom {
	padding-top: $footer-inner--padding-v / 4;
}

.footer-blocks {
	display: flex;
	flex-wrap: wrap;
	margin-right: -($footer-block--padding_h / 2);
	margin-left: -($footer-block--padding_h / 2);
	margin-top: -($footer-block--padding_v / 2);

	&:last-of-type {
		margin-bottom: -($footer-block--padding_v / 2) + $footer-blocks--padding;
	}

	&:not(:last-of-type) {
		margin-bottom: ($footer-block--padding_v / 2);
	}
}

.footer-block {
	flex-grow: 1;
	flex-basis: $footer-block--min-width;
	box-sizing: content-box;
	padding: $footer-block--padding_v / 2 $footer-block--padding_h / 2;

	* {
		box-sizing: border-box;
	}

	a {
		@include anchor-aspect(footer-block);
		@include font-weight(footer-block-link);
		text-transform: $link-footer-block--transform;

		.invert-color & {
			@include anchor-aspect(footer-block, inverse);
		}
	}
}

.footer-block-title {
	@include font-size(block-title);
	@include font-weight(block-title);
	color: color(block-title);
	text-transform: $footer-block-title--transform;
	margin-bottom: $footer-block-title-padding;

	.invert-color & {
		color: color(block-title-inverse);
	}
}

.footer-social,
.footer-nav {
	a {
		display: flex;
	}

	.list-reset {
		display: flex;
		white-space: nowrap;
		margin-bottom: 0;
	}
}

.footer-social {
	display: flex;

	.social-item {
		width: 32px;
		height: 32px;
		display: flex;
		border-radius: 50%;
		margin-right: 20px;
		align-items: center;
		justify-content: center;
		text-decoration: none !important;
		background: rgba(255, 105, 0, .1);
		padding: 0 $footer-social--outer-padding-h;

		.iconfont {
			font-size: 32px;
		}
	}

	a {
		text-decoration: none;
		padding: $footer-social--inner-padding;

		.iconfont {
			color: color-icon(social);
		}

		svg {
			transition: fill .15s ease;

			.invert-color & {
				fill: color-icon(social-inverse);
			}
		}

		&:hover {

			svg {
				fill: color-icon(social-hover);

				.invert-color & {
					fill: color-icon(social-hover-inverse);
				}
			}
		}
	}
}

.footer-nav {
	margin-right: -$footer-nav--padding-h;
	margin-left: -$footer-nav--padding-h;

	a {
		@include anchor-aspect(footer);
		@include font-weight(footer-link);
		text-transform: $link-footer--transform;
		padding: 0 $footer-nav--padding-h;

		.invert-color & {
			@include anchor-aspect(footer, inverse);
		}
	}
}

@include media( '<=medium' ) {

	.footer-top,
	.footer-bottom {

		> *:not(:last-child) {
			margin-bottom: $footer-inner--padding-v;
		}
	}
}

@include media( '>medium' ) {

	@if ( $footer--padding__mobile != $footer--padding__desktop ) {

		.site-footer-inner {
			padding: 58px 0;
		}
	}

	.footer-top,
	.footer-bottom {

		&.space-between {
			display: flex;
			justify-content: space-between;
			align-items: center;
		}
	}

	.footer-top,
	.footer-bottom {

		&.invert-order-desktop {

			> *:first-child {
				order: 1;
			}
		}
	}
}

.site-footer {
	position: relative;

	&::before {
		content: '';
		bottom: 0;
		width: 100%;
		height: 100%;
		right: 210px;
		position: absolute;
		background-repeat: no-repeat;
		background-size: 510px 205px;
		background-position: top right;
		background-image: url('../../../images/footer-bg.png');
	}

	.social-item {
		margin-right: 0;
		margin-left: 20px;
		background: rgba(0, 0, 0, 0);

		.iconfont {
			font-size: 32px;
			color: #ffffff;
		}
	}

	.footer-bottom,
	.footer-nav .list-reset li a {
		color: #ffffff;
		text-decoration: none;
	}

	&::after {
		content: '';
		left: 0;
		bottom: 0;
		z-index: -1;
		width: 100%;
		height: 100%;
		position: absolute;
		background-color: rgba(42, 42, 42, .9);
	}
}