@include media( '<=medium' ) {
	.split-wrap {
		.split-item {
			.split-item-content {				
				margin-bottom: $split-item--inner-padding-v_mobile;

				p {
					line-height: 1.5 !important;
					font-size: 16px !important;
				}
			}
		}

		&.invert-mobile {
			.split-item {
				.split-item-content {
					order: 1;
					margin-bottom: 0 !important;
				}

				.split-item-image {
					margin-bottom: $split-item--inner-padding-v_mobile;
				}
			}
		}
	}
}

@include media( '>medium' ) {
	.split-wrap {
		margin-top: -($split-item--padding-v__desktop / 2);

		&:last-of-type {
			margin-bottom: -($split-item--padding-v__desktop / 2);
		}

	    &:not(:last-of-type) {
	        margin-bottom: $split-item--padding-v__desktop / 2;
	    }

		.split-item {
			padding-top: $split-item--padding-v__desktop / 2;
			padding-bottom: $split-item--padding-v__desktop / 2;
			flex-direction: row;
			flex-wrap: nowrap;
			align-items: center;

			.split-item-content {
				margin-bottom: 0;
				width: 560px;
			}
		}

		&:not(.invert-desktop) .split-item:nth-child(even),
		&.invert-desktop .split-item:nth-child(odd) {
			.split-item-content {
				order: 1;
			}
		}

		&:not(.invert-desktop) .split-item:nth-child(odd),
		&.invert-desktop .split-item:nth-child(even) {
			.split-item-image {
				img,
				svg,
				video {
					margin-left: auto;
				}
			}
		}
	}
}

.split-wrap {
	margin-top: -($split-item--padding-v__mobile / 2);

	&:last-of-type {
		margin-bottom: -($split-item--padding-v__mobile / 2);
	}

    &:not(:last-of-type) {
        margin-bottom: $split-item--padding-v__mobile / 2;
    }

	.split-item {
		display: flex;
		padding-top: $split-item--padding-v__mobile / 2;
		padding-bottom: $split-item--padding-v__mobile / 2;

		.split-item-image {
			width: 320px;
			height: 320px;
			flex-basis: 320px;
			position: relative;

			img,
			svg,
			video {
				width: 100%;
				height: 100%;
				max-width: 100%;
				overflow: visible;
			}

			&.split-item-image-fill {
				img,
				svg,
				video {
					width: 100%;
				}
			}
		}
	}

	.about-item {
		width: 920px;
		display: flex;
		justify-content: space-between;

		.split-item-content {
			width: 560px;
			margin-bottom: 0;
			line-height: 1.6;
			display: inline-block;

			p {
				color: rgba(0, 0, 0, .6);
			}
		}
	}
}